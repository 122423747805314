import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths } from '../../utils/localStorage'
import {
    ExecutorStepContentMemos,
    UserShipmentAddressInputErrors,
    UserPhoneInputErrors,
    UserNameInputErrors,
    LBCykelPlusInputErrors,
    NumberOfBikesInHouseholdInputErrors,
} from '../../types/userFlows'

export type FlowTemplateParameterKeys = 'fullyQualifiedFlowMemo' | 'stepMemo'

export interface FlowTemplateParameters {
    fullyQualifiedFlowMemo: string | undefined
    stepMemo: string | undefined | null
}

export interface InputState<E> {
    errors?: E[]
}

export interface UserNameInputState extends InputState<UserNameInputErrors> {
    firstName?: string
    lastName?: string
}

export interface UserPhoneInputState extends InputState<UserPhoneInputErrors> {
    mobile?: string
    telephone?: string
}

export interface UserShipmentAddressInputState extends InputState<UserShipmentAddressInputErrors> {
    street_address?: string
    city?: string
    zip_code?: string
    floor?: string
    side?: string
    door?: string
}

export interface NumberOfBikesInHouseholdInputState
    extends InputState<NumberOfBikesInHouseholdInputErrors> {
    number_of_bikes?: number
}

export interface LBCykelPlusInputState extends InputState<LBCykelPlusInputErrors> {
    cykelplus?: 'true' | 'false'
}

export interface StepContentState
    extends Partial<Record<ExecutorStepContentMemos, InputState<unknown>>> {
    user_name_input?: UserNameInputState
    user_phone_input?: UserPhoneInputState
    user_shipment_address_input?: UserShipmentAddressInputState
    number_of_bikes_in_household_input?: NumberOfBikesInHouseholdInputState
    lb_cykel_plus_input?: LBCykelPlusInputState
}

export interface StepStates extends Record<string, Record<string, StepContentState>> {}

export interface FlowTemplateState {
    parameters: FlowTemplateParameters
    stepStates: StepStates
}

function createEmptyState(): FlowTemplateState {
    return {
        parameters: {
            fullyQualifiedFlowMemo: undefined,
            stepMemo: undefined,
        },
        stepStates: {},
    }
}

function initializeState() {
    const existingValues = getItem(tokenPaths.createFlowTemplateDraftValues)
    if (existingValues != null) {
        return JSON.parse(existingValues)
    }
    return createEmptyState()
}

function storeNewState(newState: FlowTemplateState): FlowTemplateState {
    setItem(tokenPaths.createFlowTemplateDraftValues, JSON.stringify(newState))
    return newState
}

const slice = createSlice({
    name: 'flowTemplate',
    initialState: initializeState(),
    reducers: {
        setState: (state: any, action: PayloadAction<FlowTemplateParameters>) => {
            return storeNewState({
                ...state,
                parameters: action.payload,
            })
        },
        updateState: (state: any, action: PayloadAction<Partial<FlowTemplateParameters>>) => {
            return storeNewState({
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload,
                },
            })
        },
        updateStepState: (state: any, action: PayloadAction<Partial<StepStates>>) => {
            const newState = {
                ...state,
                stepStates: {
                    ...state.stepStates,
                },
            }
            for (const fqfm of Object.keys(action.payload)) {
                const flowState = action.payload[fqfm] || {}
                for (const stepMemo of Object.keys(flowState)) {
                    const stepState = flowState[stepMemo]
                    for (const stepContentMemo of Object.keys(
                        stepState
                    ) as ExecutorStepContentMemos[]) {
                        newState.stepStates[fqfm] = { ...(newState.stepStates[fqfm] || {}) }
                        newState.stepStates[fqfm][stepMemo] = {
                            ...(newState.stepStates[fqfm][stepMemo] || {}),
                        }
                        newState.stepStates[fqfm][stepMemo][stepContentMemo] =
                            stepState[stepContentMemo]
                    }
                }
            }
            return storeNewState(newState)
        },
        reset: (state: any) => {
            return storeNewState(createEmptyState())
        },
    },
})

export const { setState, reset, updateState, updateStepState } = slice.actions

export default slice.reducer
