import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths } from '../../utils/localStorage'

export interface InsuranceOnboardingParameters {
    insurer_party_id: string | undefined
    party_id: string | undefined | null
    policy_id: string | undefined
    insurance_coverage: string | undefined
    email: string | undefined
    first_name: string | undefined
    last_name: string | undefined
    street_address: string | undefined
    city: string | undefined
    zip_code: number | undefined
    mobile: number | undefined
    telephone: number | undefined
    floor: number | undefined
    side: string | undefined
    door: string | undefined
    frame_number: string | undefined
    number_of_bikes: string | undefined
    acknowledgement_privacy: boolean | undefined | null
    acknowledgement_data_share: boolean | undefined | null
    acknowledgement_ownership_transfer: boolean | undefined | null
}

export type ErrorKeys =
    | 'missing_input'
    | 'missing_insurer_party_id'
    | 'missing_email'
    | 'missing_policy_id'
    | 'unknown_credentials'

export interface InsuranceOnboardingState {
    parameters: InsuranceOnboardingParameters
    errors: ErrorKeys[]
}

function createEmptyState(): InsuranceOnboardingState {
    return {
        parameters: {
            insurer_party_id: undefined,
            party_id: undefined,
            policy_id: undefined,
            insurance_coverage: undefined,
            email: undefined,
            first_name: undefined,
            last_name: undefined,
            street_address: undefined,
            city: undefined,
            zip_code: undefined,
            mobile: undefined,
            telephone: undefined,
            floor: undefined,
            side: undefined,
            door: undefined,
            frame_number: undefined,
            number_of_bikes: undefined,
            acknowledgement_privacy: undefined,
            acknowledgement_data_share: undefined,
            acknowledgement_ownership_transfer: undefined,
        },
        errors: [],
    }
}

function initializeState() {
    const existingValues = getItem(tokenPaths.createInsuranceOnboardingDraftValues)
    if (existingValues != null) {
        return JSON.parse(existingValues)
    }
    return createEmptyState()
}

function storeNewState(newState: InsuranceOnboardingState): InsuranceOnboardingState {
    setItem(tokenPaths.createInsuranceOnboardingDraftValues, JSON.stringify(newState))
    return newState
}

const slice = createSlice({
    name: 'insuranceOnboarding',
    initialState: initializeState(),
    reducers: {
        setState: (state: any, action: PayloadAction<InsuranceOnboardingParameters>) => {
            return storeNewState({
                ...state,
                parameters: action.payload,
            })
        },
        updateState: (
            state: any,
            action: PayloadAction<Partial<InsuranceOnboardingParameters>>
        ) => {
            return storeNewState({
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload,
                },
            })
        },
        addError: (state: any, action: PayloadAction<Partial<ErrorKeys>>) => {
            return storeNewState({
                ...state,
                errors: [...state.errors, action.payload],
            })
        },
        removeError: (state: any, action: PayloadAction<Partial<ErrorKeys>>) => {
            return storeNewState({
                ...state,
                errors: state.errors.filter((e: ErrorKeys) => e !== action.payload),
            })
        },
        removeAllErrors: (state: any) => {
            return storeNewState({
                ...state,
                errors: [],
            })
        },
        reset: (state: any) => {
            return storeNewState(createEmptyState())
        },
    },
})

export const { setState, reset, updateState, addError, removeError, removeAllErrors } =
    slice.actions

export default slice.reducer
